function ready(fn) {
    if (
        document.attachEvent
            ? document.readyState === "complete"
            : document.readyState !== "loading"
    ) {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

ready(function () {
    document.documentElement.classList.remove("no-js");
    document.documentElement.classList.add("js");

    const datetimeToUnix = (stamp) =>
        Math.floor(new Date(stamp).getTime() / 1000);

    const initForm = () => {
        const form = document.getElementById("ntfy");

        if (!form) return;

        // Set min and max times for 'send later' option (min 15 mins from now, max 3 days from now)
        const in15mins = new Date(new Date().getTime() + 15 * 60000)
            .toISOString()
            .slice(0, 16);
        const m = new Date();
        m.setDate(m.getDate() + 3);
        const maxDate = m.toISOString().slice(0, 16);

        const timeInput = form.querySelector("#time");
        timeInput.setAttribute("min", in15mins);
        timeInput.setAttribute("max", maxDate);
        timeInput.value = in15mins;

        const handleSubmit = (ev) => {
            ev.preventDefault();
            form.classList.add("hidden");
            const formData = new FormData(ev.target);
            const formProps = Object.fromEntries(formData);

            let params = {
                method: "POST",
                body: formProps.message,
            };

            if (formProps.delay === "on" && formProps.time) {
                const timestamp = datetimeToUnix(formProps.time);
                params.headers = {
                    At: `${timestamp}`,
                };
            }

            fetch(`https://ntfy.sh/${formProps.channel}`, params).then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    document.querySelector("#result").innerText = "Success";
                    document.querySelector(
                        "#resultinfo"
                    ).innerHTML = `<p><strong class="font-bold">${formProps.channel
                    }</strong> will be reminded - <strong class="font-bold">${formProps.message
                        }</strong> at <strong class="font-bold">${formProps.time ? formProps.time.replace("T", " @ ") : "now"
                        }</strong></p>`;

                    const items = JSON.parse(localStorage.getItem("sent")) || [];
                    items.push({
                        ...formProps,
                        time:
                            formProps.delay === "on"
                                ? formProps.time
                                : new Date(new Date().getTime()).toISOString().slice(0, 16),
                    });
                    localStorage.setItem("sent", JSON.stringify(items));
                } else {
                    document.querySelector("#result").innerText =
                        "Something went wrong :(";
                    document.querySelector(
                        "#resultinfo"
                    ).innerText = `Check the info and try again - send later date must be at least 15 mins from now, at most 3 days`;
                    form.classList.remove("hidden");
                }
            });
        };
        form.addEventListener("submit", handleSubmit);
    };

    const initViewPrevious = () => {
        const historicWrap = document.querySelector("#historic");
        const clearAll = document.querySelector("#clear");

        if (!historicWrap) return;

        const items = JSON.parse(localStorage.getItem("sent"));

        if (!items || !items.length) {
            clearAll.style.display = "none";
            historicWrap.innerHTML = `<li>No items</li>`;
            return;
        }

        items.reverse().forEach((item) => {
            const i = document.createElement("li");
            i.innerHTML = `<p>
      <strong class="font-bold text-sm leading-loose">${item.channel
                } - ${item.time.replace("T", " @ ")}</strong><br/>
      ${item.message}
      </p>`;
            historicWrap.appendChild(i);
        });

        clearAll.addEventListener("click", () => {
            localStorage.setItem("sent", "[]");
            initViewPrevious();
        });
    };

    initForm();
    initViewPrevious();
});
